<template>
  <v-select
    v-model="input"
    :hint="hint"
    :items="donors"
    :menu-props="{maxHeight:'250'}"
    :rules="[ val => !!val || 'Donor required!' ]"
    dense
    label="Select donor"
    required
    persistent-hint
    box
    validate-on-blur
  />
</template>

<script>
import { createGetParams } from '@/rest'

export default {
  name: 'MnpDonors',
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    donors: [],
    hint: 'Donor required',
    input: null,
  }),
  watch: {
    input: function (val) {
      if (val) {
        this.input !== this.value && this.$emit('input', val)
      }
    },
    value: function (val) {
      if (val) this.input = val
    },
  },
  mounted: function () {
    this.$rest.get('getDonors.php', createGetParams({}))
      .then(response => {
        for (const item of response.data) {
          this.donors.push(item.donor)
        }
      })
    if (this.value) this.input = this.value
  },
}
</script>
